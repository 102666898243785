import React from 'react';

import {
  VDateField,
  VInputField,
  VFieldWrapper,
  VRadioGroupField,
} from '@ventera-corporation/vstart-components-react';

import './DateTime.scss';

interface DateTimeProps {
  name: string;
  dateLabel: string;
  timeLabel: string;
  defaultDate?: string;
  defaultTime?: string;
}

function DateTime(props: DateTimeProps) {
  const { name, dateLabel, timeLabel, defaultDate, defaultTime } = props;
  return (
    <div className="col pb-2 date-time-wrapper">
      <VFieldWrapper
        // @ts-ignore
        label=""
      >
        <VDateField
          // @ts-ignore
          name={`${name}Date`}
          label={dateLabel}
          placeholder="MM/DD/YYYY"
          defaultValue={defaultDate}
          max="9999-12-31"
          required
        />
        <div className="time-wrapper">
          <VInputField
            // @ts-ignore
            name={`${name}Time`}
            label={timeLabel}
            maxlength="5"
            placeholder="HH:MM"
            size="5"
            className="d-inline-block hours-minutes"
            defaultValue={defaultTime}
            required
          />
          <div className="ampm-wrapper">
            <VRadioGroupField
              name={`${name}AMPM`}
              // @ts-ignore
              fields={[`${name}AMPM`]}
              options={[
                { value: 'am', label: 'AM' },
                { value: 'pm', label: 'PM' },
              ]}
            />
          </div>
        </div>
      </VFieldWrapper>
    </div>
  );
}

export default DateTime;
