import React, { useEffect, useLayoutEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';

import {
  VCollapsibleGroup,
  VCollapsibleSection,
  VLoader,
} from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

import { RefDataQuery } from 'modules/types';

import CallSign from './components/call-sign/CallSign';
import Captcha from './components/captcha/Captcha';
import ContactInformation from './components/contact-information/ContactInformation';
import InterferenceDetails from './components/interference-details/InterferenceDetails';
import MeetingDetails from './components/meeting-details/MeetingDetails';
import SubmitterEmail from './components/submitter-email/SubmitterEmail';

enum FORM_SECTION_NAMES {
  DEFAULT = 'DEFAULT',
  CALLSIGN = 'CALLSIGN',
  SUBMITTER_EMAIL = 'SUBMITTER_EMAIL',
  CONTACT_INFO = 'CONTACT_INFO',
  INTERFERENCE_DETAILS = 'INTERFERENCE_DETAILS',
  MEETING_DETAILS = 'MEETING_DETAILS',
  CAPTCHA = 'CAPTCHA',
}

function FormSections({
  isSubmitting,
  setIsSubmitting,
  isCaptchaError,
  searchDebounced,
  selectedLocation,
  interferenceLocations,
  setSelectedLocation,
  isLoggedIn,
  apiErrors = [],
}: any) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [openItem, setOpenItem] = useState(FORM_SECTION_NAMES.DEFAULT);
  const { formState, setError, getValues } = useFormContext();
  const errorKeys = Object.keys(formState.errors);

  const { data = {} as RefDataQuery, isLoading } = useQuery(
    ['complaint_refdata'],
    () => API.getRefData(),
    { refetchOnWindowFocus: false },
  );

  const hasErrorsInCallSign = errorKeys.some((key) => key.includes('callsign'));
  const hasErrorsInSubmitter = errorKeys.some((key) =>
    key.includes('submittedBy'),
  );
  const hasErrorsInContactInfo = errorKeys.some(
    (key) => key.startsWith('pc') || key.startsWith('tc'),
  );
  const hasErrorsInInferenceDetails = errorKeys.some((key) =>
    key.includes('interference'),
  );
  const hasErrorsInMeetingDetails = errorKeys.some((key) =>
    key.includes('meeting'),
  );
  const hasErrorsInCaptcha = errorKeys.some((key) =>
    key.includes('captchaInput'),
  );

  const [firstError] = errorKeys;

  const toggle = () => {
    setIsSubmitting(false);
    if (!isInitialized) {
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    if (isSubmitting) {
      apiErrors.forEach((e: any) => {
        setError(e.field, { message: e.error });
      });

      if (hasErrorsInCallSign) {
        setOpenItem(FORM_SECTION_NAMES.CALLSIGN);
      } else if (hasErrorsInSubmitter) {
        setOpenItem(FORM_SECTION_NAMES.SUBMITTER_EMAIL);
      } else if (hasErrorsInContactInfo) {
        setOpenItem(FORM_SECTION_NAMES.CONTACT_INFO);
      } else if (hasErrorsInInferenceDetails) {
        setOpenItem(FORM_SECTION_NAMES.INTERFERENCE_DETAILS);
      } else if (hasErrorsInMeetingDetails) {
        setOpenItem(FORM_SECTION_NAMES.MEETING_DETAILS);
      } else if (hasErrorsInCaptcha) {
        setOpenItem(FORM_SECTION_NAMES.CAPTCHA);
      } else {
        setOpenItem(FORM_SECTION_NAMES.DEFAULT);
      }

      if (!isInitialized) {
        setIsInitialized(true);
      }
    }

    if (isCaptchaError) {
      setOpenItem(FORM_SECTION_NAMES.CAPTCHA);
    }
  }, [
    hasErrorsInCallSign,
    hasErrorsInSubmitter,
    hasErrorsInContactInfo,
    hasErrorsInInferenceDetails,
    hasErrorsInMeetingDetails,
    hasErrorsInCaptcha,
    isSubmitting,
    setIsSubmitting,
    isCaptchaError,
    isInitialized,
    apiErrors,
    setError,
  ]);

  useLayoutEffect(() => {
    if (isSubmitting && openItem !== FORM_SECTION_NAMES.DEFAULT) {
      setIsSubmitting(false);
      document.getElementsByName(firstError)?.[0]?.focus();
    }
  });

  if (isLoading) return <VLoader />;

  const { ORG_TYPE, RECEIVER_MAKE, RECEIVER_MODEL, TIMEZONE, STATE } =
    data.data || {};

  return (
    <VCollapsibleGroup key={openItem}>
      <VCollapsibleSection
        sectionId="callSign"
        title="Call Sign"
        defaultIsOpen={
          !isInitialized || openItem === FORM_SECTION_NAMES.CALLSIGN
        }
        handleClick={toggle}
      >
        <CallSign
          callsign={getValues().callsign}
          error={formState?.errors?.callsign?.message}
        />
      </VCollapsibleSection>

      {!isLoggedIn ? (
        <VCollapsibleSection
          sectionId="submitterEmail"
          title="Submitter Information"
          defaultIsOpen={openItem === FORM_SECTION_NAMES.SUBMITTER_EMAIL}
          handleClick={toggle}
        >
          <SubmitterEmail />
        </VCollapsibleSection>
      ) : (
        <></>
      )}

      <VCollapsibleSection
        sectionId="contactInfo"
        title="Contact Information"
        defaultIsOpen={openItem === FORM_SECTION_NAMES.CONTACT_INFO}
        handleClick={toggle}
      >
        <ContactInformation orgTypes={ORG_TYPE} states={STATE} />
      </VCollapsibleSection>

      <VCollapsibleSection
        sectionId="interferenceDetails"
        title="Interference Details"
        defaultIsOpen={openItem === FORM_SECTION_NAMES.INTERFERENCE_DETAILS}
        handleClick={toggle}
      >
        <InterferenceDetails
          timezones={TIMEZONE}
          receiverMake={RECEIVER_MAKE}
          receiverModel={RECEIVER_MODEL}
          searchDebounced={searchDebounced}
          selectedLocation={selectedLocation}
          interferenceLocations={interferenceLocations}
          setSelectedLocation={setSelectedLocation}
        />
      </VCollapsibleSection>

      <VCollapsibleSection
        sectionId="meetingDetails"
        title="Meeting Details"
        defaultIsOpen={openItem === FORM_SECTION_NAMES.MEETING_DETAILS}
        handleClick={toggle}
      >
        <MeetingDetails timezones={TIMEZONE} />
      </VCollapsibleSection>

      {!isLoggedIn ? (
        <VCollapsibleSection
          sectionId="captcha"
          title="Captcha"
          defaultIsOpen={openItem === FORM_SECTION_NAMES.CAPTCHA}
          handleClick={toggle}
        >
          <Captcha />
        </VCollapsibleSection>
      ) : (
        <></>
      )}
    </VCollapsibleGroup>
  );
}

export default FormSections;
