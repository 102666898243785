import React from 'react';

import {
  VSingleColView,
  VInputField,
} from '@ventera-corporation/vstart-components-react';

import './CallSign.scss';
import { ERROR_CALLSIGN_API } from 'api/constants';

const customError = (callsign: string, error?: string) => {
  if (error !== ERROR_CALLSIGN_API) {
    return (
      <div className="callsign-error-wrapper">
        <p>Call Sign is a required field</p>
      </div>
    );
  }
  return (
    <div className="callsign-error-wrapper">
      <p>
        The callsign you have entered ({callsign}) is either not in the FCC's
        ULS database, or is in the FCC's ULS database but is listed as not
        active.
        <br />
        Only valid 800MHz licensees may report interference through this system.
        <br />
        Please enter an active callsign to continue.
      </p>
      <p>
        If you believe you have received this message in error, please email{' '}
        <a href="mailto:InterferenceSiteSupport@ventera.com">
          InterferenceSiteSupport@ventera.com
        </a>{' '}
        with your call sign.
      </p>
    </div>
  );
};

interface CallSignProps {
  callsign: string;
  error?: string;
}

function CallSign({ callsign, error }: CallSignProps) {
  return (
    <div className="call-sign-wrapper">
      <VSingleColView size="full">
        <h4 className="font-weight-bold mb-4">FCC Identification</h4>
      </VSingleColView>
      <p>
        Please enter ONE FCC call sign for the affected service. This call sign
        will be validated against the FCC ULS.
      </p>
      <div className="col pb-2">
        <VInputField
          // @ts-ignore
          name="callsign"
          label="FCC-Issued Call Sign"
          helpText="This is the valid, FCC-issued call sign for the radio license which is experiencing the interference you are reporting. If you do not know your call sign, please contact a technical representative within your organization."
          errorComponent={() => customError(callsign, error)}
          required
        />
      </div>
    </div>
  );
}

export default CallSign;
