import React, { useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router';

import {
  VLoader,
  useAuthentication,
  VButton,
  VAlert,
  usePageUpdate,
} from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

import { PAGE_CREATE_COMPLAINT, PAGE_HOME } from 'site-map';

function LandingPage() {
  const [showSuccess, setShowSuccess] = useState(false);
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const { state = {} as any } = useLocation();
  const { success = '' } = state || {};

  usePageUpdate({
    documentTitle: PAGE_HOME.title,
    header: {},
  });

  const queryComplaints = () => API.getComplaints(user.email);
  const { data = {} as any, isLoading } = useQuery(
    ['complaints_data'],
    queryComplaints,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  );

  const complaints = data.data || [];

  useEffect(() => {
    setShowSuccess(success);
  }, [success]);

  if (isLoading) return <VLoader />;

  const viewComplaint = (id: string) => {
    navigate(`/complaint/${id}`);
  };

  const closeAlert = () => {
    setShowSuccess(false);
  };

  return (
    <div className="landing-page-wrapper ctia-content-wrapper">
      <div className="ctia-content">
        <Container>
          <VAlert
            showAlert={showSuccess}
            heading="Success!"
            variant="success"
            setShowAlert={closeAlert}
          >
            <p>
              Thank you! Your interference report has been submitted. A record
              has been sent to {user.email}.
            </p>
            <p>
              You can find your recent submission in the list of Submitted
              Reports below.
            </p>
          </VAlert>
          <h1 id="landing-header" data-testid="landing-header-id">
            Welcome {user.firstName}!
          </h1>
          <p>Need to report an interference incident?</p>

          <VButton href={PAGE_CREATE_COMPLAINT()}>
            Create Interference Complaint
          </VButton>

          <h2 className="mt-4">Submitted Interference Complaints</h2>
          {!complaints.length ? <p>No previously submitted reports.</p> : null}
          {complaints.map((m: any, index: number) => {
            return (
              <div key={index} className="interference-report-item">
                <VButton
                  className="px-0 py-2"
                  onClick={() => viewComplaint(m.value.toString())}
                  variant="link"
                >
                  {m.label}
                </VButton>
              </div>
            );
          })}
        </Container>
      </div>
    </div>
  );
}

export default LandingPage;
