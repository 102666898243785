import React, { useState } from 'react';

import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { VLoader } from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

function AcknowledgeComplaint() {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const params = useParams();

  const { data = {} as any, isLoading } = useQuery(
    ['acknowledge-complaint', params?.id, params?.code],
    () => API.acknowledgeComplaint(params?.id || '', params?.code || ''),
    {
      cacheTime: Infinity,
      onSuccess: () => setIsSuccessful(true),
      onError: () => setIsSuccessful(false),
    },
  );

  if (isLoading) return <VLoader />;

  const isAcknowledged = data.data;

  let heading = 'Error Acknowledging Complaint';
  let content = (
    <p>
      There was an error while processing the acknowledgement. Please refresh
      the page to try again. If this error persists please inform
      <a href="mailto:InterferenceComplaint@publicsafety800mhzInterference.com">
        InterferenceComplaint@publicsafety800mhzInterference.com
      </a>
    </p>
  );

  if (isSuccessful) {
    heading = 'Complaint Acknowledgement';
    if (isAcknowledged) {
      content = (
        <p>
          Thank you for acknowledging this complaint. Please follow up with the
          complainant as specified in the email you received.
        </p>
      );
    } else {
      content = <p>This complaint has already been acknowledged.</p>;
    }
  }

  return (
    <div className="ctia-content-wrapper">
      <div className="my-4 ctia-content-title">
        <Container className="contact-us-wrapper">
          <h1>{heading}</h1>
        </Container>
      </div>
      <div className="ctia-content">
        <Container>{content}</Container>
      </div>
    </div>
  );
}

export default AcknowledgeComplaint;
