export function formatPhone(phone = '') {
  if (!phone) return '';

  const phoneCleaned = phone.replace(/[^0-9]/g, '');

  return `${phoneCleaned.substring(0, 3)}-${phoneCleaned.substring(
    3,
    6,
  )}-${phoneCleaned.substring(6)}`;
}
