import React, { useState } from 'react';

import { Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';

import {
  useAuthentication,
  usePageUpdate,
  VForm,
  VLoader,
  VSelectField,
} from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

import { RefDataQuery } from 'modules/types';

import RunReports from '../../components/run-reports/RunReports';
import { PAGE_HOME } from 'site-map';

function FCCLanding() {
  const { user } = useAuthentication();

  const [carrierId, setCarrierId] = useState('0');

  usePageUpdate({
    documentTitle: PAGE_HOME.title,
    header: {},
  });

  const { data = {} as RefDataQuery, isLoading } = useQuery(
    ['complaint_refdata'],
    () => API.getRefData(),
    { refetchOnWindowFocus: false },
  );

  if (isLoading) return <VLoader />;

  const refData = data?.data || {};
  const { CARRIER = [] } = refData;

  const selectCarrier = (e: any) => {
    setCarrierId(e?.target?.value);
  };

  return (
    <div className="carrier-landing-wrapper ctia-content-wrapper">
      <div className="ctia-content-title">
        <Container className="my-4 d-flex">
          <h1 id="landing-header" data-testid="landing-header-id">
            Welcome {user.firstName || 'FCC'}!
          </h1>
        </Container>
      </div>
      <div className="ctia-content">
        <Container className="run-reports-wrapper pb-4">
          <Row>
            <VForm onSubmit={() => {}}>
              <div className="select-field">
                <VSelectField
                  // @ts-ignore
                  name="carrier"
                  label="Select carrier"
                  showDefault={false}
                  defaultValue="0"
                  options={[{ value: '0', label: 'All Carriers' }, ...CARRIER]}
                  handleChange={selectCarrier}
                />
              </div>
            </VForm>
          </Row>
        </Container>
        <RunReports key={carrierId} carrierId={carrierId} />
      </div>
    </div>
  );
}

export default FCCLanding;
