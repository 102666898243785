import React from 'react';

import { Container } from 'react-bootstrap';

import {
  usePageUpdate,
  VCollapsibleGroup,
  VCollapsibleSection,
} from '@ventera-corporation/vstart-components-react';

import ContactUs from '../components/contact-us/ContactUs';
import FAQ from '../components/faq/FAQ';
import SponsoringCarriers from '../components/sponsoring-carriers/SponsoringCarriers';
import { PAGE_HELP } from 'site-map';

import './Help.scss';

function Help() {
  usePageUpdate({
    documentTitle: PAGE_HELP.title,
    header: {
      breadCrumbs: [{ title: 'Help' }],
    },
  });

  return (
    <div className="help-wrapper ctia-content-wrapper" data-testid="help-id">
      <div className="ctia-content-title">
        <Container className="my-4 d-flex">
          <h1>Help</h1>
        </Container>
      </div>
      <div className="ctia-content">
        <Container>
          <VCollapsibleGroup>
            <VCollapsibleSection
              sectionId="contact-us-section"
              title="Contact Us"
            >
              <ContactUs />
            </VCollapsibleSection>

            <VCollapsibleSection
              sectionId="sponsoring-carriers-section"
              title="Sponsoring Carriers"
            >
              <SponsoringCarriers />
            </VCollapsibleSection>

            <VCollapsibleSection sectionId="faq-section" title="FAQ">
              <FAQ />
            </VCollapsibleSection>
          </VCollapsibleGroup>
        </Container>
      </div>
    </div>
  );
}

export default Help;
