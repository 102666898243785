import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './app/App';

import './assets/styles/index.scss';

library.add(fas as any);
library.add(far as any);

if (process.env.REACT_APP_GTM_KEY) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_KEY,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
