import { createStore } from '@ventera-corporation/vstart-components-react';

import { rootReducer } from './rootReducer';

export const getStore = () => {
  // pass in custom redux stuff here, including middleware
  const { store } = createStore({
    customReducers: rootReducer,
  });
  return store;
};
