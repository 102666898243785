import { format, addMonths, differenceInMonths } from 'date-fns';

import { groupBy } from 'utils/group-by';

import { getExactDate } from './get-exact-date';

interface ChartDataItem {
  date: string;
  complaintId: number;
  interference: {
    state: string;
    country: string;
    zip: string;
    location: string;
  };
}

export function groupChartData(
  chartData: ChartDataItem[] = [],
  startDate: string,
  endDate: string,
) {
  const dataMapped = chartData.map((d: any) => {
    const exactDate = getExactDate(d.date);
    return {
      ...d,
      dateByYear: format(exactDate, 'yyyy'),
      dateByMonth: format(exactDate, 'MM-yyyy'),
    };
  });

  let grouped = groupBy(dataMapped, 'dateByMonth');

  // add missing months
  if (chartData.length) {
    const exactStartDate = getExactDate(startDate);
    const exactEndDate = getExactDate(endDate);
    const diff = differenceInMonths(exactStartDate, exactEndDate);

    for (let i = 0; i <= Math.abs(diff); i = i + 1) {
      const nextDate = addMonths(exactStartDate, i);
      const currentMMMYY = format(nextDate, 'MM-yyyy');

      if (!grouped[currentMMMYY]) {
        const addEmptyMonth = {
          date: format(nextDate, "yyyy-MM-dd'T'00:00:00.000"),
          dateByYear: nextDate.getFullYear().toString(),
          dateByMonth: currentMMMYY,
        };
        dataMapped.push(addEmptyMonth);
        grouped[currentMMMYY] = [addEmptyMonth];
      }
    }
  }

  // re-group data after adding months
  if (Object.keys(grouped).length > 12) {
    grouped = groupBy(dataMapped, 'dateByYear');

    // rendering too many x-axis items crashes chart
    if (Object.keys(grouped).length > 12) {
      const removedEmpties = dataMapped.filter((d) => !!d.complaintId);
      grouped = groupBy(removedEmpties, 'dateByYear');
    }
  }

  const final = Object.keys(grouped)
    .map((key: string) => {
      const items = grouped[key].filter((g: any) => g.complaintId);
      return {
        date: key,
        count: items.length,
        data: items,
        fullDate: grouped[key]?.[0]?.date,
      };
    })
    .sort((a, b) => (new Date(a.fullDate) > new Date(b.fullDate) ? 1 : -1));

  return final;
}
