import React from 'react';

function ContactUs() {
  return (
    <div className="ctia-content-wrapper" data-testid="contact-us-id">
      <h2>Self-Service Assistance</h2>
      <p>For answers to common questions, please check our FAQ section.</p>

      <h2>Technical Support</h2>
      <p>
        If you have any questions, please contact our 24x7 technical toll-free
        at 866-729-2204 or send an email to{' '}
        <a href="mailto:InterferenceSiteSupport@ventera.com">
          InterferenceSiteSupport@ventera.com
        </a>
      </p>
    </div>
  );
}

export default ContactUs;
