import React, { useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';

import { usePageUpdate } from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

import RunReports from '../../components/run-reports/RunReports';
import { PAGE_HOME } from 'site-map';

function CarrierLanding() {
  const [internalUser, setInternalUser] = useState({} as any);

  usePageUpdate({
    documentTitle: PAGE_HOME.title,
    header: {},
  });

  const init = async () => {
    const results = await API.getUser();
    setInternalUser(results?.data);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="carrier-landing-wrapper ctia-content-wrapper">
      <div className="ctia-content-title">
        <Container className="my-4 d-flex">
          <h1 id="landing-header" data-testid="landing-header-id">
            Welcome {internalUser?.carrierName || 'Carrier'}!
          </h1>
        </Container>
      </div>
      <div className="ctia-content">
        <RunReports carrierId={internalUser?.carrierId} />
      </div>
    </div>
  );
}

export default CarrierLanding;
