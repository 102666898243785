import qs from 'qs';

import { api } from 'api/api';
import * as API from 'api/constants';

import * as T from './routes.types';

// USER
export async function getUser() {
  const url = `${API.API_CTIA_SERVICE}/user`;
  return await api.get(url);
}

// COMPLAINANTS
export async function getLatestComplaint() {
  const url = `${API.API_CTIA_SERVICE}/complaint/latest`;
  return await api.get(url);
}

export async function getComplaints(email: string) {
  const url = `${API.API_CTIA_SERVICE}/complaint/submitted?email=${email}`;
  return await api.get(url);
}

export async function getComplaint(id: string) {
  const url = `${API.API_CTIA_SERVICE}/complaint/${id}`;
  return await api.get(url);
}

export async function addComplaint(data: any) {
  const url = `${API.API_CTIA_SERVICE}/complaint`;
  return await api.post(url, data);
}

export async function acknowledgeComplaint(id: string, code: string) {
  const url = `${API.API_CTIA_SERVICE}/complaint/${id}/acknowledge?code=${code}`;
  return await api.put(url, {});
}

export async function getRefData() {
  const url = `${API.API_CTIA_SERVICE}/refdata/`;
  return await api.get(url);
}

export async function getLocations(queryParams: T.LocationsQueryParams) {
  const { params, ...rest } = queryParams;
  const query = qs.stringify({ ...params, ...rest });
  const url = `${API.API_CTIA_SERVICE}/refdata/location/filter?${query}&limit=${API.COMPLAINTS_MAX_RESULTS}`;
  return await api.get(url);
}

export async function validateCallsign(callsign: string) {
  const url = `${API.API_SERVICE}/call-sign/validate/${callsign}`;
  return await api.get(url);
}

// CARRIERS
export async function getNotifications() {
  const url = `${API.API_CTIA_SERVICE}/notifications/`;
  return await api.get(url);
}

interface ChartDataParams {
  startDate: string;
  endDate: string;
  carrierId: number;
  email: string;
}

export async function getChartData(configs: ChartDataParams) {
  const { startDate, endDate, carrierId, email } = configs;
  const url = `${API.API_CTIA_SERVICE}/carrier/complaints?startDate=${startDate}&endDate=${endDate}&carrierId=${carrierId}&email=${email}`;
  return await api.get(url);
}

interface DownloadReportParams {
  startDate: string;
  endDate: string;
  carrierId: number;
  isZip: boolean;
}

export async function getReportUrl(configs: DownloadReportParams) {
  const { startDate, endDate, carrierId, isZip } = configs;
  const url = `${API.API_CTIA_SERVICE}/carrier/complaints/report?startDate=${startDate}&endDate=${endDate}&carrierId=${carrierId}&isZip=${isZip}`;
  return url;
}

export async function getLocationData(carrierId: number | string) {
  const url = `${API.API_CTIA_SERVICE}/carrier/location/report?carrierId=${carrierId}`;
  return url;
}

export async function getAssociatedEmails() {
  const url = `${API.API_CTIA_SERVICE}/carrier/emails`;
  return await api.get(url);
}
