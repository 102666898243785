import React from 'react';

import { createConfirmation } from '@ventera-corporation/vstart-components-react';

export function getModalCancel({ cancelConfirm }: any) {
  return createConfirmation({
    actions: [
      { label: 'Yes', variant: 'primary', value: true },
      { label: 'No', variant: 'secondary', value: false },
    ],
    callback: cancelConfirm,
    children: (
      <p>
        You will lose all data entered in the form. Are you sure you want to
        cancel?
      </p>
    ),
  });
}
