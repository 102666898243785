import * as pathToRegex from 'path-to-regexp';

export const TITLE_PREFIX = '800MHz - ';

// Fn to create a route path and page title
function createPath([path, title]: string[]) {
  const reverse = pathToRegex.compile(path);
  function method(params?: any, match?: any): string {
    if (params) {
      return reverse({ ...params, ...match });
    }

    return path;
  }
  method.title = TITLE_PREFIX + title;

  return method;
}

// General pages (Home, sign in, profile, etc.)
export const PAGE_HOME = createPath(['/', 'Home']);
export const PAGE_REGISTRATION = createPath([
  '/registration',
  'User Registration',
]);
export const PAGE_SIGN_IN = createPath(['/sign-in', 'Sign In']);
export const PAGE_SIGN_IN_CONFIRMATION = createPath([
  '/sign-in-confirmation',
  'Sign In Confirmation',
]);
export const PAGE_MY_PROFILE = createPath(['/my-profile', 'My Profile']);
export const PAGE_MY_PROFILE_EDIT = createPath([
  '/my-profile/edit',
  'Edit My Profile',
]);

// Error pages
export const PAGE_404_ERROR = createPath(['/404', 'Page Not Found']);
export const PAGE_500_ERROR = createPath(['/500', 'Page Error']);

// Complaints pages
export const PAGE_CREATE_COMPLAINT = createPath([
  '/complaint/create',
  'Create Interference Complaint',
]);
export const PAGE_CREATE_COMPLAINT_SUCCESS = createPath([
  '/complaint/create/success',
  'Create Interference Complaint Success',
]);
export const PAGE_VIEW_COMPLAINT = createPath([
  '/complaint/:id',
  'View Complaint',
]);
export const PAGE_ACKNOWLEDGE_COMPLAINT = createPath([
  '/complaint/:id/acknowledge/:code',
  'Complaint Acknowledged',
]);

// Carrier pages
export const PAGE_HELP = createPath(['/help', 'Help']);
export const PAGE_GLOSSARY = createPath(['/glossary', 'Glossary']);
