import React, { useEffect } from 'react';

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  // @ts-ignore
} from 'react-simple-captcha';

import { VInputField } from '@ventera-corporation/vstart-components-react';

function Captcha() {
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  return (
    <div className="captcha-wrapper">
      <div className="form-group">
        <div className="col mt-3">
          <LoadCanvasTemplate />
        </div>

        <div className="col mt-3">
          <div>
            <VInputField
              // @ts-ignore
              id="captchaInput"
              name="captchaInput"
              type="text"
              label="Enter Captcha"
              required
            ></VInputField>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Captcha;
