import React from 'react';

import {
  VInputField,
  VFieldWrapper,
} from '@ventera-corporation/vstart-components-react';

import './InterferenceDetails.scss';

function LatLon({ name, namePretty, helpText }: any) {
  return (
    <div className="col pb-2 lat-lon-wrapper">
      <VFieldWrapper
        // @ts-ignore
        label={namePretty}
      >
        <div className="lat-lon-dddmmss">
          <VInputField
            // @ts-ignore
            name={`${name}Degree`}
            label="ddd"
            className="d-inline-block lat-lon"
            placeholder="ddd"
            isLabelHidden={true}
            type="text"
            size="3"
            maxlength="3"
          />
          <VInputField
            // @ts-ignore
            name={`${name}Minutes`}
            label="mm"
            className="d-inline-block lat-lon"
            placeholder="mm"
            isLabelHidden={true}
            size="3"
            maxlength="2"
          />
          <VInputField
            // @ts-ignore
            name={`${name}Seconds`}
            label="ss"
            className="d-inline-block lat-lon"
            placeholder="ss"
            isLabelHidden={true}
            size="3"
            maxlength="2"
          />
        </div>

        <p className="mt-3">OR</p>

        <VInputField
          // @ts-ignore
          name={`${name}Atlas`}
          label="Decimal Format"
          className="lat-lon-decimal"
          placeholder="e.g. 38.9062"
          isLabelHidden={true}
          helpText={helpText}
        />
      </VFieldWrapper>
    </div>
  );
}

export default LatLon;
