import logo from 'assets/images/logo_white.svg';

import './Footer.scss';

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <h2 className="visually-hidden">Footer Links</h2>
        <div className="firstRow">
          <div>
            <img src={logo} alt="800MHz" width="75%" />
          </div>
        </div>
        <div>
          <div>
            <span>© {year} 800MHz Interference Notification</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
