import {
  loadCaptchaEnginge,
  validateCaptcha,
  // @ts-ignore
} from 'react-simple-captcha';

import { ERROR_CALLSIGN_API, ERROR_FORM_GENERIC } from 'api/constants';
import * as API from 'api/pages/routes';

import * as PAGES from 'site-map';

import { getErrorKeysMap } from './getErrorKeysMap';
import { parsePayload } from './parsePayload';

async function processSubmission(params: any) {
  const {
    payload,
    isLoggedIn,
    setErrors,
    navigate,
    setIsProcessingForm,
    setIsSubmitting,
  } = params;
  const complaint = parsePayload(payload);
  setIsProcessingForm(true);

  let errors: any = [];
  // validate callsign - external API
  const validateCallsign = await API.validateCallsign(payload.callsign);
  if (validateCallsign?.status !== 200) {
    errors.push({ field: 'callsign', error: ERROR_CALLSIGN_API });
  }

  // internal API call
  let results = {} as any;
  if (!errors.length) {
    ({ data: results } = await API.addComplaint(complaint));
  }
  if (results.status === 400) {
    const formErrors = results.violations.map((v: any) => {
      return getErrorKeysMap(v);
    });
    errors = [...errors, ...formErrors];
  } else if (results.status > 400) {
    errors = [
      ...errors,
      {
        field: ERROR_FORM_GENERIC,
        error: 'Something went wrong. Please try again later.',
      },
    ];
  }

  if (errors.length) {
    if (!isLoggedIn) {
      loadCaptchaEnginge(6);
    }
    setErrors(errors);
    setIsProcessingForm(false);
    setIsSubmitting(true);
  } else {
    setIsProcessingForm(false);
    if (!isLoggedIn) {
      navigate(PAGES.PAGE_CREATE_COMPLAINT_SUCCESS(), {
        state: {
          email: payload.submittedBy,
          complaintData: results,
        },
      });
    } else {
      navigate(PAGES.PAGE_HOME(), {
        state: {
          success: true,
        },
      });
    }
  }
}

export async function handleFormSubmit(params: any) {
  const { isLoggedIn, setIsCaptchaError, payload, setErrors, setIsSubmitting } =
    params;
  if (isLoggedIn) {
    await processSubmission(params);
  } else {
    if (validateCaptcha(payload.captchaInput) === true) {
      setIsCaptchaError(false);
      await processSubmission(params);
    } else {
      loadCaptchaEnginge(6);
      setErrors([
        {
          field: 'captchaInput',
          error: 'Captcha value does not match the image.',
        },
      ]);
      setIsCaptchaError(true);

      // @ts-ignore
      document.getElementById('captchaInput-id').value = '';
    }
    setIsSubmitting(true);
  }
}
