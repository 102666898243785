interface Violation {
  fieldName: string;
  message: string;
}

const LOCATION_KEYS = [
  'rfiZip',
  'rfiState',
  'interferenceCountyValidation',
  'interferenceStateValidation',
  'interferenceZipValidation',
];

const MAP_KEYS: any = {
  timeOfInterference: 'interferenceTime',
};

export function getErrorKeysMap(violation: Violation) {
  if (LOCATION_KEYS.includes(violation.fieldName)) {
    return {
      field: 'interferenceLocation',
      error:
        'Interference Location must be in the format: County, State, Zipcode',
    };
  }
  return {
    field: MAP_KEYS[violation.fieldName] || violation.fieldName,
    error: violation.message,
  };
}
