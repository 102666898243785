import React from 'react';

import { useFormContext } from 'react-hook-form';

import { VDateField } from '@ventera-corporation/vstart-components-react';

// import './ReportDates.scss';

function ReportDates({ searchDebounced, startDate, endDate }: any) {
  const { clearErrors, formState } = useFormContext();

  return (
    <div className="form-date-wrapper">
      <VDateField
        // @ts-ignore
        defaultValue={startDate}
        name="startDate"
        label="Start Date"
        handleChange={(e: any) =>
          searchDebounced(e, formState.errors, clearErrors)
        }
        required={true}
        max="9999-12-31"
      />
      <VDateField
        // @ts-ignore
        defaultValue={endDate}
        name="endDate"
        label="End Date"
        handleChange={(e: any) =>
          searchDebounced(e, formState.errors, clearErrors)
        }
        required={true}
        max="9999-12-31"
      />
    </div>
  );
}

export default ReportDates;
