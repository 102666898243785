import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  cognitoUrl,
  usePageUpdate,
  useVSkipNav,
  useAuthentication,
  VButton,
} from '@ventera-corporation/vstart-components-react';

import * as PAGES from 'site-map';

import './SignInPage.scss';

function SignIn() {
  useVSkipNav('splash');
  usePageUpdate({
    documentTitle: PAGES.PAGE_SIGN_IN.title,
    header: {},
  });
  const { isLoading, user } = useAuthentication();

  const navigate = useNavigate();

  const location: any = useLocation();
  const { from } = location.state || {
    from: { pathname: PAGES.PAGE_HOME() },
  };

  useEffect(() => {
    if (user && !isLoading) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, location.state, from, isLoading]);

  return (
    <div id="sign-in-wrapper" data-testid="sign-in-page">
      <div id="top-content-wrapper">
        <div id="splash">
          <div id="welcome">
            <h1 className="visually-hidden">800MHz</h1>
            <h2>800MHz Interference Notification</h2>
            <p>
              Welcome to Public Safety 800MHz Interference Notification Site!
            </p>
            <p>
              Login to quickly submit a report using saved information or view
              submitted reports
            </p>
            <div className="buttons">
              <VButton
                href={cognitoUrl(from.pathname)}
                data-testid="external-cognito-sign-in"
              >
                Log In
              </VButton>
              <VButton
                onClick={() => navigate(PAGES.PAGE_CREATE_COMPLAINT())}
                data-testid="create-complaint-no-auth"
                variant="secondary"
              >
                Create Interference Complaint as Guest
              </VButton>
            </div>
            <br />
            Or&nbsp;
            <VButton href={cognitoUrl(from.pathname, true)} variant="link">
              Create an Account?
            </VButton>
          </div>
        </div>
      </div>
      <div id="content-wrapper">
        <div className="content">
          <div>
            <h2>About 800 MHz</h2>
          </div>
          <p>
            The purpose of this website is to facilitate interference complaints
            in the 800MHz spectrum band. Public safety, critical infrastructure
            industries, and B/ILT 800 MHz licensees can use this site to submit
            a report to local wireless carriers about a particular interference
            incident. Carriers operating within 5000 feet of the reported
            interference will investigate, and those responsible for any harmful
            interference will take action to resolve it. Upon receiving
            submitted interference report, local wireless carriers will respond
            to complaints from public safety organizations and critical
            infrastructure industries within 24 hours, and from other
            organizations within 48 hours.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
