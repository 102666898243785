import React from 'react';

import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import {
  VSingleColView,
  VSelectField,
  VInputField,
  VTextAreaField,
  VFieldHelpText,
} from '@ventera-corporation/vstart-components-react';

import './InterferenceDetails.scss';

import { RefDataObject } from 'modules/types';

import DateTime from '../date-time/DateTime';

import LatLon from './LatLon';
import Receiver from './Receiver';

interface InterferenceDetailsProps {
  searchDebounced: any;
  selectedLocation: any;
  setSelectedLocation: any;
  interferenceLocations: any;
  timezones: RefDataObject[];
  receiverMake: RefDataObject[];
  receiverModel: RefDataObject[];
}

function InterferenceDetails(props: InterferenceDetailsProps) {
  const {
    searchDebounced,
    selectedLocation,
    setSelectedLocation,
    interferenceLocations,
    timezones = [],
    receiverMake = [],
    receiverModel = [],
  } = props;
  const { setValue } = useFormContext();

  const selectLocation = (location: string) => {
    setValue('interferenceLocation', location);
    setSelectedLocation(location);
  };

  const handleBlur = (e: any) => {
    setSelectedLocation(e?.target?.value);
  };

  return (
    <div
      className="interference-details-wrapper"
      data-testid="interference-test-id"
    >
      <VSingleColView size="full">
        <p className="font-weight-bold mb-4">
          Please enter the details of the interference below. Please be as
          specific and as accurate as possible.
        </p>
      </VSingleColView>

      <div className="col pb-2">
        <VTextAreaField
          // @ts-ignore
          name="interferenceDescription"
          label="Description of Interference"
          helpText="Please enter a description of what you experienced while using your radio, including the nature and severity of the interference. To convey the nature, you might indicate that you heard multiple conversations, or that you heard static, or that the connection died, or that the transmission became garbled. To convey the severity, you might mention that it was unusably bad, or mildly annoying, etc."
          maxLength="4000"
          required
        />
      </div>

      <div id="locations-data-wrapper" className="col pb-2">
        <VInputField
          // @ts-ignore
          name="interferenceLocation"
          label="Interference Location"
          handleChange={searchDebounced}
          handleBlur={handleBlur}
          helpText="Enter County, State, or Zip Code and select correct Interference Location."
          required
        />
        {interferenceLocations?.total_count && !selectedLocation ? (
          <div id="locations-data">
            {interferenceLocations?.results?.map((l: string, index: number) => (
              <div
                key={`location-${index}`}
                className={`location-${index}`}
                onMouseDown={() => selectLocation(l)}
              >
                {l}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="col pb-2">
        <VTextAreaField
          // @ts-ignore
          name="addlLocDetails"
          label="Additional Location Information"
          helpText="Please use this field to log other location-specific information such as an intersection or a landmark. You might also indicate that the interference was only in one place, or occurred for 100 yards on one road, or only near a particular building."
          maxLength="4000"
          required
        />
      </div>

      <DateTime
        name="interference"
        dateLabel="Date of Interference"
        timeLabel="Time of Interference"
        defaultDate={format(new Date(), 'yyyy-MM-dd')}
      />

      <div className="col pb-2 mt-2">
        <div className="select-field">
          <VSelectField
            // @ts-ignore
            name="interferenceTimezone"
            label="Time Zone"
            options={timezones.map((t) => ({
              value: t.value.toString(),
              label: t.label,
            }))}
            required
          />
        </div>
      </div>

      <div className="col mt-4">
        <VFieldHelpText
          helpId="lat-lon-helpId"
          helpText="If you know the precise coordinates of the interference please provide them. Coordinates can be entered in degrees-minutes-seconds or decimal format."
        />

        <LatLon
          name="lat"
          namePretty="Latitude"
          helpText="Positive values indicate north"
        />

        <LatLon
          name="lon"
          namePretty="Longitude"
          helpText="Positive values indicate west"
        />
      </div>

      <div className="col pb-2">
        <VTextAreaField
          // @ts-ignore
          name="sourceOfInterference"
          label="Source of Interference (if known)"
          helpText="If you believe you already know the source of the interference, please describe it here in as much detail as possible."
          maxLength="4000"
        />
      </div>

      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name="frequenciesImpacted"
          label="Frequences Impacted"
          maxlength="200"
          helpText="Please provide the exact frequencies on which the interference is happening. The carriers will need this information to diagnose and troubleshoot the interference."
        />
      </div>

      <div>
        <Receiver receiverMake={receiverMake} receiverModel={receiverModel} />
      </div>

      <div className="col pb-2">
        <VInputField
          // @ts-ignore
          name="receiverSignalLevel"
          label="Receiver Signal Level"
          maxlength="200"
          helpText="This can vary depending on what equipment you are using. If you are unsure what to put here, please leave it blank."
        />
      </div>

      <div className="col pb-2">
        <VInputField
          // @ts-ignore
          name="receiverPerformanceLevel"
          label="Receiver Performance Level"
          maxlength="200"
          helpText="This can vary depending on what equipment you are using. If you are unsure what to put here, please leave it blank."
        />
      </div>
    </div>
  );
}

export default InterferenceDetails;
