import { format } from 'date-fns';

import { getExactDate } from './get-exact-date';

export function getBarDetailsDate(date = '') {
  const dateSplit = date.split('-').filter((val: string) => val);

  // we just have the year
  if (dateSplit.length === 1) {
    return dateSplit[0];
  }

  const [month, year] = dateSplit;
  const dateStringPretty = format(
    getExactDate(`${year}-${month}-01`),
    'MMMM yyyy',
  );
  return dateStringPretty;
}
