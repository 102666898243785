import React, { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import {
  VSingleColView,
  VInputField,
  VSelectField,
  VRadioGroupField,
} from '@ventera-corporation/vstart-components-react';

import { RefDataObject } from 'modules/types';

import AddressFields from './AddressFields';
import './ContactInformation.scss';

const fields = [
  'FirstName',
  'LastName',
  'PhoneNumber',
  'PhoneExt',
  'EmailAddress',
  'ConfirmEmail',
  'FaxNumber',
  'Address1',
  'Address2',
  'City',
  'State',
  'Zip',
];

interface ContactInformationProps {
  orgTypes: RefDataObject[];
  states: RefDataObject[];
}

function ContactInformation(props: ContactInformationProps) {
  const { orgTypes = [], states = [] } = props;
  const { getValues, setValue, clearErrors } = useFormContext();
  const sameContactDefault = getValues().primaryContactSameAsTechnical === '1';
  const [sameContact, setSameContact] = useState(sameContactDefault);

  const formValues = getValues();

  const showOrHideTechContact = (item: { value: string; label: string }) => {
    if (item.value === '1') {
      fields.forEach((field: string) => {
        setValue(`tc${field}`, formValues[`pc${field}`]);
        clearErrors(`tc${field}`);
      });
    } else {
      fields.forEach((field: string) => {
        setValue(`tc${field}`, '');
      });
    }
    setSameContact(item.value === '1');
  };

  return (
    <div className="contact-information-wrapper">
      <VSingleColView size="full">
        <h4 className="font-weight-bold mb-4">
          Organization &amp; Contact Info
        </h4>
      </VSingleColView>

      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name="orgName"
          label="Organization Name"
          helpText="This is the complete official name of your organization; ideally, you should use the same organization name that appears on the FCC license for the affected service."
          maxlength="50"
          required
        />
      </div>
      <div className="col-md pb-2">
        <div className="select-field">
          <VSelectField
            // @ts-ignore
            name="orgTypeId"
            label="Organization Type"
            helpText="Please choose the organization type that most accurately describes the organization that owns the FCC license for the affected service."
            options={orgTypes.map((ot) => ({
              value: ot.value.toString(),
              label: ot.label,
            }))}
            required
          />
        </div>
      </div>

      {/* PRIMARY CONTACT */}
      <AddressFields
        contactType="pc"
        contactTypePretty="Primary"
        requiredFields={[
          'pcFirstName',
          'pcLastName',
          'pcPhoneNumber',
          'pcEmailAddress',
          'pcConfirmEmail',
          'pcAddress1',
          'pcCity',
          'pcState',
          'pcZip',
        ]}
        states={states}
      />

      {/* TECHNICAL CONTACT */}
      <VRadioGroupField
        name="primaryContactSameAsTechnical"
        // @ts-ignore
        fields={['primaryContactSameAsTechnical']}
        // @ts-ignore
        helpText="Is your primary contact the same as your technical contact?"
        options={[
          { value: '1', label: 'Yes' },
          { value: '0', label: 'No' },
        ]}
        handleChange={showOrHideTechContact}
      />

      <AddressFields
        contactType="tc"
        contactTypePretty="Technical"
        requiredFields={['tcFirstName', 'tcLastName']}
        states={states}
        isDisabled={sameContact}
      />
    </div>
  );
}

export default ContactInformation;
