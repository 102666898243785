import React, { useEffect, useState } from 'react';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classnames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import {
  VButton,
  usePageUpdate,
  useVSkipNav,
  VForm,
  VSingleColView,
  VLoader,
  useAuthentication,
} from '@ventera-corporation/vstart-components-react';

import { ERROR_FORM_GENERIC } from 'api/constants';
import * as API from 'api/pages/routes';

import { PAGE_CREATE_COMPLAINT, PAGE_HOME } from 'site-map';

import FormSections from './FormSections';
import { schema } from './schema';
import { getModalCancel } from './utils/getModalCancel';
import { handleFormSubmit } from './utils/handleFormSubmit';
import { initializeFormData } from './utils/initializeFormData';

import './CreateComplaint.scss';

function CreateComplaint() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCaptchaError, setIsCaptchaError] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [interferenceLocations, setInterferenceLocations] = useState({});
  const [errors, setErrors] = useState([]);
  const [isProcessingForm, setIsProcessingForm] = useState(false);

  const { user } = useAuthentication();

  const navigate = useNavigate();

  useVSkipNav('create-complaint');
  usePageUpdate({
    documentTitle: PAGE_CREATE_COMPLAINT.title,
    header: {
      breadCrumbs: [{ title: 'Create Interference Complaint' }],
    },
  });

  useEffect(() => {
    if (user?.isFcc || user?.isCarrier) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data = {} as any, isLoading } = useQuery(
    ['user_recent_complaints'],
    () => API.getLatestComplaint(),
    { refetchOnWindowFocus: false, enabled: !!user },
  );

  const cancelConfirm = (confirmed: boolean) => {
    if (confirmed) {
      navigate(PAGE_HOME());
    }
  };

  const showCancelModal = () => {
    getModalCancel({ cancelConfirm });
  };

  const submit = async (payload: any) => {
    await handleFormSubmit({
      isLoggedIn: !!user,
      payload: {
        ...payload,
        submittedBy: payload.submittedBy || user?.email,
      },
      setIsCaptchaError,
      setErrors,
      navigate,
      setIsProcessingForm,
      setIsSubmitting,
    });
  };

  const searchLocations = async (inputRef: any) => {
    setSelectedLocation('');
    const searchValue = inputRef?.target?.value;
    if (searchValue) {
      const results = await API.getLocations({
        params: { criteria: searchValue },
      });
      setInterferenceLocations(results?.data);
    } else {
      setInterferenceLocations({});
    }
  };

  const searchDebounced = AwesomeDebouncePromise(searchLocations, 1000);

  if (isLoading) return <VLoader />;

  const formDefaultValues: any = initializeFormData(data?.data, !!user);

  const captchaSchema = !!user
    ? {}
    : {
        submittedBy: yup.string().email().required(),
        captchaInput: yup.string().required(),
      };
  const complaintSchema = schema(captchaSchema);

  const formError: any = errors.find(
    (e: any) => e.field === ERROR_FORM_GENERIC,
  );

  return (
    <div className="create-complaint-wrapper ctia-content-wrapper">
      <div className="ctia-content-title">
        <Container className="my-4 d-flex">
          <h1>Interference Complaint Form</h1>
        </Container>
      </div>
      <div className="ctia-content">
        <Container>
          <Row id="create-complaint" className="mb-4">
            <Col>
              <VForm
                key="complaint-form"
                onSubmit={submit}
                schema={complaintSchema}
                className="CreateComplaint"
                // @ts-ignore
                mode="onBlur"
                defaultValues={formDefaultValues}
              >
                <VSingleColView size="full" className="mt-2">
                  <h2 className="font-weight-bold border-bottom pb-3">
                    Create Complaint
                  </h2>
                </VSingleColView>

                <FormSections
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  isCaptchaError={isCaptchaError}
                  searchDebounced={searchDebounced}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  interferenceLocations={interferenceLocations}
                  isLoggedIn={!!user}
                  apiErrors={errors}
                />

                {isProcessingForm ? <VLoader /> : null}

                {formError ? (
                  <p className="vstart-error">{formError.error}</p>
                ) : null}
                <VSingleColView className="mt-4">
                  <VButton
                    className={classnames('me-2', {
                      disabled: isProcessingForm,
                    })}
                    type="submit"
                    onClick={() => setIsSubmitting(true)}
                  >
                    Submit
                  </VButton>
                  <VButton
                    variant="outline-primary"
                    onClick={showCancelModal}
                    disabled={isProcessingForm}
                  >
                    Cancel
                  </VButton>
                </VSingleColView>
              </VForm>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CreateComplaint;
