import React from 'react';

import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import {
  usePageUpdate,
  VLoader,
} from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

import './ViewComplaint.scss';
import { formatPhone } from 'utils/format-phone';

import * as PAGES from 'site-map';

const EMPTY = 'no response';

function ViewComplaint() {
  const params = useParams();
  const { state = {} as any } = useLocation();
  const { complaintData = {} } = state || {};
  const complaintId = complaintData?.id;

  const { data = {} as any, isLoading } = useQuery(
    ['complaint'],
    () => API.getComplaint(params?.id || ''),
    { refetchOnWindowFocus: false },
  );

  const crumbNav = complaintId
    ? [
        {
          title: 'Create Interference Complaint',
          link: PAGES.PAGE_CREATE_COMPLAINT(),
        },
        { title: 'Submission Confirmation' },
        { title: 'Complaint Details' },
      ]
    : [{ title: 'Complaint Details' }];
  usePageUpdate({
    documentTitle: PAGES.PAGE_VIEW_COMPLAINT.title,
    header: {
      breadCrumbs: crumbNav,
    },
  });

  if (isLoading) return <VLoader />;

  const item = data?.data || complaintData;

  return (
    <div className="view-complaint-wrapper ctia-content-wrapper">
      <div className="ctia-content-title">
        <Container>
          <h1 id="details">Complaint {item.internalComplaintId}</h1>
        </Container>
      </div>
      <div className="ctia-content">
        <Container>
          <div className="heading py-2">
            <h2>Complaint Details</h2>
          </div>

          <hr />

          {/* CALL SIGN */}
          <h3>Call Sign</h3>
          <div>
            <section>
              <span>FCC Identification</span>
              <span>{item.callsign}</span>
            </section>
          </div>

          {/* SUBMITTER */}
          <h3>Submitter Information</h3>
          <div>
            <section>
              <span>Submitter Email</span>
              <span>{item.submittedBy}</span>
            </section>
          </div>

          {/* CONTACT */}
          <h3>Contact Information</h3>
          <div>
            <h4>Organization &amp; Contact Info</h4>
            <section>
              <span>Organization Name</span>
              <span>{item.orgName}</span>
            </section>

            <section>
              <span>Organization Type</span>
              <span>{item.orgType}</span>
            </section>

            {/* PRIMARY CONTACT */}
            <h4>Primary Contact Information</h4>
            <div className="inline-section">
              <section>
                <span>First Name</span>
                <span>{item.pcFirstName}</span>
              </section>

              <section>
                <span>Last Name</span>
                <span>{item.pcLastName}</span>
              </section>
            </div>

            <section>
              <span>Phone Number</span>
              <span>
                {formatPhone(item.pcPhoneNumber)} ext.{' '}
                {item.pcPhoneExt || EMPTY}
              </span>
            </section>

            <section>
              <span>Email</span>
              <span>{item.pcEmailAddress}</span>
            </section>

            <section>
              <span>Fax</span>
              <span>{formatPhone(item.pcFaxNumber) || EMPTY}</span>
            </section>

            <section>
              <span>Address Line 1</span>
              <span>{item.pcAddress1}</span>
            </section>

            <section>
              <span>Address Line 2</span>
              <span>{item.pcAddress2 || EMPTY}</span>
            </section>

            <div className="inline-section">
              <section>
                <span>City</span>
                <span>{item.pcCity}</span>
              </section>

              <section>
                <span>State</span>
                <span>{item.pcState}</span>
              </section>

              <section>
                <span>Zipcode</span>
                <span>{item.pcZip}</span>
              </section>
            </div>

            {/* TECHNICAL CONTACT */}
            <h4>Technical Contact Information</h4>
            <div className="inline-section">
              <section>
                <span>First Name</span>
                <span>{item.tcFirstName}</span>
              </section>

              <section>
                <span>Last Name</span>
                <span>{item.tcLastName}</span>
              </section>
            </div>

            <section>
              <span>Phone Number</span>
              <span>
                {item.tcPhoneNumber || EMPTY} ext.: {item.tcPhoneExt || EMPTY}
              </span>
            </section>

            <section>
              <span>Email</span>
              <span>{item.tcEmailAddress || EMPTY}</span>
            </section>

            <section>
              <span>Fax</span>
              <span>{item.tcFaxNumber || EMPTY}</span>
            </section>

            <section>
              <span>Address Line 1</span>
              <span>{item.tcAddress1 || EMPTY}</span>
            </section>

            <section>
              <span>Address Line 2</span>
              <span>{item.tcAddress2 || EMPTY}</span>
            </section>

            <div className="inline-section">
              <section>
                <span>City</span>
                <span>{item.tcCity || EMPTY}</span>
              </section>

              <section>
                <span>State</span>
                <span>{item.tcState || EMPTY}</span>
              </section>

              <section>
                <span>Zipcode</span>
                <span>{item.tcZip || EMPTY}</span>
              </section>
            </div>
          </div>

          {/* INTERFERNCE */}
          <h3>Interference Details</h3>
          <div>
            <section>
              <span>Interference Location</span>
              <span>
                {item.rfiCounty}, {item.rfiState}, {item.rfiZip}
              </span>
            </section>

            <section>
              <span>Additional Location Information</span>
              <span>{item.addlLocDetails || EMPTY}</span>
            </section>

            <section>
              <span>Date and Time of Interference</span>
              <span>{item.timeOfInterference}</span>
            </section>

            <section>
              <span>Interference Timezone</span>
              <span>{item.interferenceTimeZone}</span>
            </section>

            <section>
              <span>Source of Interference</span>
              <span>{item.sourceOfInterference || EMPTY}</span>
            </section>

            <section>
              <span>Frequencies Impacted</span>
              <span>{item.frequenciesImpacted || EMPTY}</span>
            </section>

            <div className="inline-section">
              <section>
                <span>Latitude</span>
                <span>{item.latDegree || EMPTY}</span>
              </section>
              <section>
                <span>&nbsp;</span>
                <span>{item.latMinutes || EMPTY}</span>
              </section>
              <section>
                <span>&nbsp;</span>
                <span>{item.latSeconds || EMPTY}</span>
              </section>
            </div>

            <section>
              <span>Latitude Atlas</span>
              <span>{item.latAtlas || EMPTY}</span>
            </section>

            <div className="inline-section">
              <section>
                <span>Longitude</span>
                <span>{item.lonDegree || EMPTY}</span>
              </section>
              <section>
                <span>&nbsp;</span>
                <span>{item.lonMinutes || EMPTY}</span>
              </section>
              <section>
                <span>&nbsp;</span>
                <span>{item.lonSeconds || EMPTY}</span>
              </section>
            </div>

            <section>
              <span>Longitude Atlas</span>
              <span>{item.lonAtlas || EMPTY}</span>
            </section>

            <section>
              <span>Receiver Make</span>
              <span>{item.receiverMake || EMPTY}</span>
            </section>

            <section>
              <span>Receiver Model</span>
              <span>{item.receiverModel || EMPTY}</span>
            </section>

            <section>
              <span>Receiver Signal Level</span>
              <span>{item.receiverSignalLevel || EMPTY}</span>
            </section>

            <section>
              <span>Receiver Performance Level</span>
              <span>{item.receiverPerformanceLevel || EMPTY}</span>
            </section>

            <section>
              <span>Description of Interference</span>
              <span>{item.interferenceDescription || EMPTY}</span>
            </section>
          </div>

          {/* MEETING DETAILS */}
          <h3>Meeting Details</h3>
          <div>
            <section>
              <span>Date and Time of Meeting</span>
              <span>{item.meetingTime}</span>
            </section>

            <section>
              <span>Meeting Timezone</span>
              <span>{item.meetingTimeZone}</span>
            </section>

            <section>
              <span>Meeting Location</span>
              <span>{item.meetingLocation}</span>
            </section>

            <section>
              <span>Additional Notes</span>
              <span>{item.meetingAddlNotes || EMPTY}</span>
            </section>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ViewComplaint;
