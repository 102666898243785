import React from 'react';

import {
  VSingleColView,
  VInputField,
  VSelectField,
} from '@ventera-corporation/vstart-components-react';

import { RefDataObject } from 'modules/types';

interface AddressFieldsProps {
  contactType: string;
  contactTypePretty: 'Primary' | 'Technical';
  requiredFields: string[];
  isDisabled?: boolean;
  states: RefDataObject[];
}
function AddressFields(props: AddressFieldsProps) {
  const {
    contactType,
    contactTypePretty,
    requiredFields,
    states,
    isDisabled = false,
  } = props;

  const emailHelpProp =
    contactType === 'pc'
      ? {
          helpText:
            'Please enter a valid email address for the primary contact person. This address can be the same as or different from the address you are using to submit this report.',
        }
      : {};

  return (
    <div>
      <VSingleColView size="full">
        <h5 className="font-weight-bold my-4">
          {contactTypePretty} Contact Information
        </h5>
      </VSingleColView>

      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}FirstName`}
          label="First Name"
          maxlength="20"
          required={requiredFields.includes(`${contactType}FirstName`)}
          disabled={isDisabled}
        />
      </div>
      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}LastName`}
          label="Last Name"
          maxlength="20"
          required={requiredFields.includes(`${contactType}LastName`)}
          disabled={isDisabled}
        />
      </div>
      <div className="row">
        <div className="col-md pb-2">
          <VInputField
            // @ts-ignore
            name={`${contactType}PhoneNumber`}
            label="Phone"
            type="text"
            placeholder="xxx-xxx-xxxx"
            required={requiredFields.includes(`${contactType}PhoneNumber`)}
            disabled={isDisabled}
          />
        </div>
        <div className="col-md pb-2">
          <VInputField
            // @ts-ignore
            name={`${contactType}PhoneExt`}
            label="Ext."
            maxLength="5"
            required={requiredFields.includes(`${contactType}PhoneExt`)}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}EmailAddress`}
          label="Email Address"
          type="email"
          maxlength="100"
          required={requiredFields.includes(`${contactType}EmailAddress`)}
          disabled={isDisabled}
        />
      </div>
      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}ConfirmEmail`}
          label="Reenter Email Address"
          type="email"
          maxlength="100"
          required={requiredFields.includes(`${contactType}ConfirmEmail`)}
          disabled={isDisabled}
          {...emailHelpProp}
        />
      </div>
      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}FaxNumber`}
          label="Fax"
          placeholder="xxx-xxx-xxxx"
          required={requiredFields.includes(`${contactType}FaxNumber`)}
          disabled={isDisabled}
        />
      </div>
      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}Address1`}
          label="Address Line 1"
          maxlength="100"
          required={requiredFields.includes(`${contactType}Address1`)}
          disabled={isDisabled}
        />
      </div>
      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}Address2`}
          label="Address Line 2"
          maxlength="100"
          required={requiredFields.includes(`${contactType}Address2`)}
          disabled={isDisabled}
        />
      </div>
      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}City`}
          label="City"
          maxlength="20"
          required={requiredFields.includes(`${contactType}City`)}
          disabled={isDisabled}
        />
      </div>
      <div className="col-md pb-2">
        <div className="select-field">
          <VSelectField
            // @ts-ignore
            name={`${contactType}State`}
            label="State"
            options={states.map((s) => ({
              // the component uses this as the unique key,
              // but the API is currently returning all -1 for s.value
              // so we're going to use the label for now
              value: s.label,
              label: s.label,
            }))}
            required={requiredFields.includes(`${contactType}State`)}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="col-md pb-2">
        <VInputField
          // @ts-ignore
          name={`${contactType}Zip`}
          label="Zip Code"
          maxlength="5"
          required={requiredFields.includes(`${contactType}Zip`)}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}

export default AddressFields;
