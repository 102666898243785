import React, { useState } from 'react';

import { VSelectField } from '@ventera-corporation/vstart-components-react';

import { RefDataObject } from 'modules/types';

interface ReceiverProps {
  receiverMake: RefDataObject[];
  receiverModel: RefDataObject[];
}

function Receiver(props: ReceiverProps) {
  const { receiverMake, receiverModel } = props;
  const [modelOptions, setModelOptions] = useState([] as any);

  const onChange = (item: any) => {
    const receiverMakeItem =
      receiverMake.find((rm) => rm.value.toString() === item.target?.value) ||
      ({} as RefDataObject);

    const models = receiverModel.filter(
      (m) => m.parentId === receiverMakeItem?.value,
    );

    setModelOptions(models);
  };

  return (
    <div className="col pb-2">
      <div className="select-field">
        <VSelectField
          // @ts-ignore
          name="receiverMakeValidation"
          label="Receiver Make"
          data-testid="receiver-make-test-id"
          options={receiverMake.map((m) => ({
            value: m.value.toString(),
            label: m.label,
          }))}
          handleChange={onChange}
        />
      </div>

      <div className="select-field">
        <VSelectField
          // @ts-ignore
          name="receiverModelValidation"
          label="Receiver Model"
          data-testid="receiver-model-test-id"
          options={modelOptions}
        />
      </div>
    </div>
  );
}

export default Receiver;
