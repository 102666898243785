import React from 'react';

function FAQ() {
  return (
    <div className="faq-wrapper ctia-content-wrapper" data-testid="faq-id">
      <h2>Who is allowed to submit an interference report?</h2>
      <p>
        Any FCC licensed operator of an 800MHz radio device, typically public
        safety, critical infrastructure industries, and business, industrial and
        land transportation 800 MHz licensees, can use this site to report
        interference.
      </p>

      <h2>When will I be contacted?</h2>
      <p>
        Representatives from the carriers will contact you at the telephone
        number you provided within 24 hours for interference reports made by
        public safety and critical infrastructure industries, and within 48
        hours for all others. If you are not contacted within these time limits,
        you may wish to contact the carriers identified in your report
        submission confirmation directly to enquire about your interference
        report.
      </p>

      <h2>I submitted a complaint but no one has contacted me.</h2>
      <p>
        This system provides redundancy when sending email notification to
        carriers, but it is possible that messages may not be delivered
        successfully. Your confirmation page and confirmation email both contain
        a list of the carriers who were determined to operate near the site of
        your interference. You can always contact those carriers directly to
        follow up with your complaint.
      </p>

      <h2>
        I submitted a complaint and although some carriers contacted me, the
        interference has not been resolved.
      </h2>
      <p>
        Please contact the carriers identified in your interference confirmation
        email directly for resolution.
      </p>

      <h2>I did not get my complaint confirmation emailed to me.</h2>
      <p>
        Many email systems block messages that are suspected of being spam.
        Please check that your email system did not quarantine or block delivery
        of the confirmation email. Check with your email system administrator or
        customer support if you are unsure how to manage your anti-spam
        configuration.
      </p>

      <p>
        Alternatively, if you register, you can log in to view your previous
        report submissions.
      </p>

      <h2>
        Do I need to attend the meeting that I scheduled during the interference
        report process?
      </h2>
      <p>
        A meeting to further investigate the cause of the interference was
        scheduled for the date, time and location you specified. Technical
        representatives of carriers operating in the area of your interference
        will be present. They will find it most helpful for reproducing and
        mitigating the interference if you or your technical contact can attend
        with your radio equipment.
      </p>

      <h2>
        I clicked "continue" or "submit" and was sent back to the sign in page.
      </h2>
      <p>
        For security purposes, users will be automatically logged out after 90
        minutes of activity. If you have been sent to a login page while
        submitting a report, it is because this 90 minute period has expired.
      </p>

      <h2>
        I received an error / The site isn't working / My submission was not
        successful
      </h2>
      <p>
        If you are having technical difficulty with any part of the website,
        please contact our 24x7 technical support toll-free at 866-729-2204. If
        possible, please maintain your Internet connection and be prepared to
        access this site while you are talking with customer support.
      </p>
    </div>
  );
}

export default FAQ;
