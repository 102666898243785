import React from 'react';

function SponsoringCarriers() {
  return (
    <div
      className="sponsoring-carriers-wrapper ctia-content-wrapper"
      data-testid="sponsoring-carriers-id"
    >
      <p>
        <strong>
          The following carriers are sponsors of and participants in this
          notification system:
        </strong>
      </p>
      <ul>
        <li>Advantage Cellular Systems</li>
        <li>Airtel Wireless</li>
        <li>AT &amp; T</li>
        <li>Bluegrass Cellular</li>
        <li>Brazos Cellular Communications</li>
        <li>Carolina West Wireless</li>
        <li>CC Communications</li>
        <li>Cellcom</li>
        <li>Cellular 29 Plus</li>
        <li>Cellular One of San Luis Obispo</li>
        <li>Centennial Communications Corporation</li>
        <li>Central MobilPhone</li>
        <li>Copper Valley</li>
        <li>Corr Wireless Communications</li>
        <li>Dobson Cellular Systems</li>
        <li>Easterbrooke Cellular</li>
        <li>Eloqui</li>
        <li>Farmers Cellular Telephone</li>
        <li>First Cellular of Southern Illinois</li>
        <li>Five Star Wireless</li>
        <li>Gogo BA</li>
        <li>Golden State Cellular</li>
        <li>Great Lakes of Iowa</li>
        <li>Illinois Valley Cellular</li>
        <li>Leaco</li>
        <li>Lyrix Wireless</li>
        <li>Midwest Wireless Holdings</li>
        <li>Nextel</li>
        <li>Northwest Missouri Cellular</li>
        <li>Peoples Wireless</li>
        <li>Pine Cellular</li>
        <li>Plateau Communications</li>
        <li>Sagebrush Cellular</li>
        <li>Smith Bagley</li>
        <li>SouthernLINC Wireless</li>
        <li>Taylor Telecommunications</li>
        <li>Triangle Communication System</li>
        <li>UBET Wireless</li>
        <li>US Cellular</li>
        <li>Valley Telecommunications Company</li>
        <li>Verizon Wireless</li>
        <li>Virginia Cellular</li>
        <li>WESTEX Wireless</li>
        <li>Wilkes</li>
        <li>Wue Wireless</li>
        <li>XIT Rural Telephone Cooperative </li>
      </ul>
    </div>
  );
}

export default SponsoringCarriers;
