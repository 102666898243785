// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { ResponsiveBar } from '@nivo/bar';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { format, differenceInMonths, subMonths } from 'date-fns';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  useAuthentication,
  VButton,
  VFieldHelpText,
  VForm,
  VRadioGroupField,
} from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

import './RunReports.scss';
import AssociatedEmails from '../associated-emails/AssociatedEmails';
import ReportDates from '../report-dates/ReportDates';

import { schema } from './schema';
import { getBarDetailsDate } from './utils/get-bardetails-date';
import { getDateFieldToClear } from './utils/get-date-field-to-clear';
import { getExactDate } from './utils/get-exact-date';
import { groupChartData } from './utils/group-chart-data';

function RunReports({ carrierId }: { carrierId: number | string }) {
  const initEndDate = format(getExactDate(), 'yyyy-MM-dd');
  const initStartDate = format(
    subMonths(new Date(initEndDate), 1),
    'yyyy-MM-dd',
  );

  const { user } = useAuthentication();

  const [nivoData, setNivoData] = useState([]);
  const [chartMessage, setChartMessage] = useState('');
  const [barDetails, setBarDetails] = useState();
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);

  const fetchChartData = async (params = {} as any) => {
    const { type, date } = params;
    const startDateQuery = type === 'startDate' ? date : startDate;
    const endDateQuery = type === 'endDate' ? date : endDate;
    const results = await API.getChartData({
      startDate: format(getExactDate(startDateQuery), 'MM/dd/yyyy'),
      endDate: format(getExactDate(endDateQuery), 'MM/dd/yyyy'),
      carrierId,
      email: user?.email,
    });

    if (results.status === 200) {
      const chartData = groupChartData(
        results?.data,
        startDateQuery,
        endDateQuery,
      );
      setNivoData(chartData);
      if (!chartData.length) {
        setChartMessage('No interference complaints.');
      } else {
        setChartMessage('');
      }
    } else if (results.status === 404) {
      setNivoData([]);
      setChartMessage('No interference complaints.');
    } else {
      setNivoData([]);
      setChartMessage('Could not load data. Please try again.');
    }
  };

  const updateDate = (e: any, errors = {} as any, clearErrors: any) => {
    const date = e?.target?.value;
    const type = e?.target?.name;

    const setDateType = type === 'startDate' ? setStartDate : setEndDate;
    setDateType(date);
    setBarDetails();

    const clearField = getDateFieldToClear({
      type,
      startDate,
      endDate,
      errors,
      date,
    });
    clearField && clearErrors(clearField);

    const matched = date.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (matched && !Object.keys(errors).length) {
      fetchChartData({ type, date });
    }
  };

  const autoDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const downloadReport = async ({ isZip }: any) => {
    const isZipBool = isZip === 'ZIP';
    const url = await API.getReportUrl({
      startDate: format(getExactDate(startDate), 'MM/dd/yyyy'),
      endDate: format(getExactDate(endDate), 'MM/dd/yyyy'),
      isZip: isZipBool,
      carrierId,
    });

    autoDownload(url);
  };

  const downloadData = async () => {
    const url = await API.getLocationData(carrierId);
    autoDownload(url);
  };

  useEffect(() => {
    if (carrierId !== undefined && carrierId !== null) {
      fetchChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierId]);

  const searchDebounced = AwesomeDebouncePromise(updateDate, 1000);

  const diff = differenceInMonths(new Date(startDate), new Date(endDate));

  return (
    <Container className="run-reports-wrapper">
      <Row>
        {/* LEFT CONTENT */}
        <Col className="content-wrapper">
          <Row>
            <VFieldHelpText
              helpId="date-help-text"
              helpText="You can select a date range to show the number of interference complaints submitted on the Timeline graph and generate a downloadable report as CSV or ZIP."
            />
          </Row>
          <Row>
            <VForm schema={schema} mode="onBlur">
              <ReportDates
                searchDebounced={searchDebounced}
                startDate={startDate}
                endDate={endDate}
              />
            </VForm>
          </Row>
          <Row>
            <h2 className="chart-title mt-4">Interference Timeline</h2>
            <VFieldHelpText
              helpId="chart-help"
              helpText="In order to see a list of interference complaint reports, click on any bar in the graph. You can also view the details of each report by selecting an individual report."
            />
            <p className="my-4">
              Showing data for {format(getExactDate(startDate), 'MM/dd/yyyy')} -{' '}
              {format(getExactDate(endDate), 'MM/dd/yyyy')}
            </p>
          </Row>
          <Row>
            <div style={{ width: '600px', height: '350px' }}>
              {chartMessage ? (
                <p className="chart-message">
                  <span>{chartMessage}</span>
                </p>
              ) : null}
              <ResponsiveBar
                width={800}
                height={350}
                data={nivoData}
                keys={['count']}
                indexBy="date"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                colorBy="id"
                tooltip={() => false}
                onMouseEnter={(a, b) => {
                  b.target?.classList.add('chart-hover');
                }}
                onMouseLeave={(a, b) => {
                  b.target?.classList.remove('chart-hover');
                }}
                defs={[
                  {
                    id: 'ctia_solid',
                    type: 'linearGradient',
                    colors: [{ color: '#4eb6bc' }],
                  },
                ]}
                fill={[{ match: '*', id: 'ctia_solid' }]}
                onClick={(point) => setBarDetails(point?.data)}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: Math.abs(diff) < 13 ? 'Month-Year' : 'Year',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  format: (e: any) => Math.floor(e) === e && e,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '# of complaints',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                role="application"
              />
            </div>
          </Row>

          <Row>
            {barDetails ? (
              <div>
                <p>
                  {barDetails.count} interference{' '}
                  {barDetails.count === 1 ? 'complaint' : 'complaints'} received
                  in {getBarDetailsDate(barDetails.date)}
                </p>
                <ul>
                  {barDetails.data?.map((d: any, index: number) => (
                    <li key={index}>
                      <Link to={`/complaint/${d?.complaintId}`}>
                        {d?.interference?.location}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </Row>
        </Col>

        {/* RIGHT PANEL */}
        <Col>
          <div className="run-reports-actions-wrapper py-3">
            <Row>
              <VForm onSubmit={downloadReport} className="download-form">
                <VRadioGroupField
                  name="isZip"
                  label="Generate Report"
                  helpText="You can choose from CSV or ZIP file to download a list of interference complaint reports based on the selected date range."
                  options={[
                    {
                      name: 'isZip-radio-csv',
                      value: 'CSV',
                      label: 'CSV',
                      isChecked: true,
                    },
                    {
                      name: 'isZip-radio-zip',
                      value: 'ZIP',
                      label: 'ZIP',
                    },
                  ]}
                />

                <VButton
                  type="submit"
                  variant="primary"
                  className="mt-4"
                  disabled={!!chartMessage}
                >
                  Run Report
                </VButton>
              </VForm>
            </Row>

            {user?.isCarrier ? (
              <Row className="mt-5">
                <div className="m-0 p-0">
                  <h3>Download My Location Data</h3>
                  <p>
                    <small className="mb-1 form-text">
                      You can get a list of all of the location data available
                      for your carrier by downloading a CSV file. The file
                      contains county and zip code information for each
                      location. If you wish to update this information, please
                      contact our{' '}
                      <a href="mailto:interferencesitesupport@ventera.com">
                        Help Desk
                      </a>
                      .
                    </small>
                  </p>
                  <VButton
                    variant="primary"
                    className="mt-4"
                    onClick={downloadData}
                  >
                    Download CSV
                  </VButton>
                </div>
              </Row>
            ) : null}

            {user?.isCarrier ? (
              <Row className="mt-5">
                <AssociatedEmails />
              </Row>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default RunReports;
