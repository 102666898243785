import * as yup from 'yup';

export const initSchema = {
  // callsign
  callsign: yup.string().required(),
  // contact information
  orgName: yup.string().required(),
  orgTypeId: yup.string().required(),
  pcFirstName: yup.string().required(),
  pcLastName: yup.string().required(),
  pcAddress1: yup.string().required(),
  pcAddress2: yup.string(),
  pcCity: yup.string().required(),
  pcState: yup.string().required(),
  pcZip: yup
    .string()
    .required()
    .matches(/^[0-9]{5}$/, {
      message: 'Zipcode must be 5 digits',
      excludeEmptyString: true,
    }),
  pcPhoneNumber: yup
    .string()
    .required()
    .matches(/^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/, {
      message: 'Phone number must be 9 numeric digits',
      excludeEmptyString: true,
    }),
  pcEmailAddress: yup.string().email().required(),
  pcConfirmEmail: yup
    .string()
    .email()
    .oneOf([yup.ref('pcEmailAddress'), null], "Emails don't match")
    .required(),
  pcFaxNumber: yup.string().matches(/^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/, {
    message: 'Fax number must be 9 numeric digits',
    excludeEmptyString: true,
  }),
  tcFirstName: yup.string().required(),
  tcLastName: yup.string().required(),
  // interference
  interferenceLocation: yup.string().required(),
  addlLocDetails: yup.string().required(),
  interferenceDate: yup
    .date()
    .required('Date of Interference is a required field')
    .typeError('Date of Interference is invalid')
    .max(new Date(), 'Date of Interference cannot be in the future'),
  interferenceTime: yup
    .string()
    .required('Time of Interference cannot be empty')
    .matches(/^[0-9]{1,2}[:][0-9]{2}$/, {
      message: 'Time of Interference must be in HH:MM format',
      excludeEmptyString: true,
    })
    .test(
      'is-valid',
      'Time of Interference must be between 01:00 and 12:59.',
      function (time = '') {
        const [hh, mm] = time?.split(':');
        if (
          parseInt(hh, 10) < 1 ||
          parseInt(hh, 10) > 12 ||
          parseInt(mm, 10) > 59
        ) {
          return false;
        }
        return true;
      },
    ),
  latDegree: yup.string().matches(/^[0-9]{3}$/, {
    message: 'ddd is the wrong format',
    excludeEmptyString: true,
  }),
  latMinutes: yup.string().matches(/^[0-9]{2}$/, {
    message: 'mm is the wrong format',
    excludeEmptyString: true,
  }),
  latSeconds: yup.string().matches(/^[0-9]{2}$/, {
    message: 'ss is the wrong format',
    excludeEmptyString: true,
  }),
  lonDegree: yup.string().matches(/^[0-9]{3}$/, {
    message: 'ddd is the wrong format',
    excludeEmptyString: true,
  }),
  lonMinutes: yup.string().matches(/^[0-9]{2}$/, {
    message: 'mm is the wrong format',
    excludeEmptyString: true,
  }),
  lonSeconds: yup.string().matches(/^[0-9]{2}$/, {
    message: 'ss is the wrong format',
    excludeEmptyString: true,
  }),
  interferenceTimezone: yup.string().required(),
  interferenceDescription: yup.string().required(),
  // meeting details
  meetingDate: yup
    .date()
    .required('Date of Meeting is a required field')
    .max(new Date('9999-12-12'), 'Date of Meeting is too far into the future.')
    .typeError('Date of Meeting is invalid'),
  meetingTime: yup
    .string()
    .required()
    .matches(/^[0-9]{1,2}[:][0-9]{2}$/, {
      message: 'Time of Meeting must be in HH:MM format',
      excludeEmptyString: true,
    })
    .test(
      'is-valid',
      'Time of Meeting must be between 01:00 and 12:59.',
      function (time = '') {
        const [hh, mm] = time?.split(':');
        if (
          parseInt(hh, 10) < 1 ||
          parseInt(hh, 10) > 12 ||
          parseInt(mm, 10) > 59
        ) {
          return false;
        }
        return true;
      },
    ),
  meetingTimezone: yup.string().required(),
  meetingLocation: yup.string().required(),
};

export const schema = (schemaShape = {} as any) =>
  yup.object().shape({
    ...initSchema,
    ...schemaShape,
  });
