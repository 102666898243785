import { isBefore } from 'date-fns';

interface VDateCompare {
  type: string;
  date: string;
  startDate: string;
  endDate: string;
  errors: {
    [key: string]: any;
  };
}

export function getDateFieldToClear({
  type,
  date,
  startDate,
  endDate,
  errors,
}: VDateCompare) {
  if (type === 'startDate') {
    const isDateCompareValid = isBefore(new Date(date), new Date(endDate));
    if (errors?.endDate?.type === 'min' && isDateCompareValid) {
      return 'endDate';
    }
  } else {
    const isDateCompareValid = isBefore(new Date(startDate), new Date(date));
    if (errors?.startDate?.type === 'min' && isDateCompareValid) {
      return 'startDate';
    }
  }
  return '';
}
