import { format } from 'date-fns';

export function parsePayload(payload: any) {
  const {
    interferenceLocation,
    orgTypeId,
    interferenceDate,
    interferenceTimezone,
    meetingTimezone,
    primaryContactSameAsTechnical,
    receiverMakeValidation,
    receiverModelValidation,
    meetingDate,
    meetingAMPM,
    interferenceAMPM,
    interferenceTime,
    ...complaintData
  } = payload;
  const [rfiCounty, rfiState, rfiZip] = interferenceLocation?.split(', ');
  const complaint = {
    ...complaintData,
    orgTypeId: parseInt(orgTypeId, 10),
    meetingTimeZoneId: parseInt(meetingTimezone, 10),
    interferenceCountyValidation: rfiCounty,
    dateOfInterference: format(new Date(interferenceDate), 'MM/dd/yyyy'),
    timeOfInterference: interferenceTime,
    interferenceStateValidation: rfiState,
    meetingDate: format(new Date(meetingDate), 'MM/dd/yyyy'),
    pcIsTc: parseInt(primaryContactSameAsTechnical, 10),
    amMeeting: meetingAMPM === 'am' ? 1 : 0,
    timeZoneId: parseInt(interferenceTimezone, 10),
    amInterference: interferenceAMPM === 'am' ? 1 : 0,
    rfiState,
    rfiCounty,
    rfiZip,
  };

  if (receiverMakeValidation) {
    complaint.receiverMakeId = parseInt(receiverMakeValidation, 10);
  }
  if (receiverModelValidation) {
    complaint.receiverModelId = parseInt(receiverModelValidation, 10);
  }

  return complaint;
}
