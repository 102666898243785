import { getDefaultDateTime } from '../components/meeting-details/getDefaultDateTime';

export function initializeFormData(
  recentComplaint = {} as any,
  isAuthenticated: boolean,
) {
  const defaultDateTime = getDefaultDateTime();
  const commonFields = {
    interferenceAMPM: 'am',
    meetingDate: defaultDateTime.date,
    meetingTime: defaultDateTime.time,
    meetingAMPM: defaultDateTime.ampm,
  };
  if (!isAuthenticated) {
    return {
      primaryContactSameAsTechnical: '0',
      ...commonFields,
    };
  }

  const complaint = {
    // primary contact
    callsign: recentComplaint.callsign,
    orgName: recentComplaint.orgName,
    orgTypeId: recentComplaint.orgTypeId,
    pcFirstName: recentComplaint.pcFirstName,
    pcLastName: recentComplaint.pcLastName,
    pcPhoneNumber: recentComplaint.pcPhoneNumber,
    pcPhoneExt: recentComplaint.pcPhoneExt,
    pcEmailAddress: recentComplaint.pcEmailAddress,
    pcConfirmEmail: recentComplaint.pcEmailAddress,
    pcFaxNumber: recentComplaint.pcFaxNumber,
    pcAddress1: recentComplaint.pcAddress1,
    pcAddress2: recentComplaint.pcAddress2,
    pcCity: recentComplaint.pcCity,
    pcState: recentComplaint.pcState,
    pcZip: recentComplaint.pcZip,
    // technical contact
    tcFirstName: recentComplaint.tcFirstName,
    tcLastName: recentComplaint.tcLastName,
    tcPhoneNumber: recentComplaint.tcPhoneNumber,
    tcPhoneExt: recentComplaint.tcPhoneExt,
    tcEmailAddress: recentComplaint.tcEmailAddress,
    tcConfirmEmail: recentComplaint.tcEmailAddress,
    tcFaxNumber: recentComplaint.tcFaxNumber,
    tcAddress1: recentComplaint.tcAddress1,
    tcAddress2: recentComplaint.tcAddress2,
    tcCity: recentComplaint.tcCity,
    tcState: recentComplaint.tcState,
    tcZip: recentComplaint.tcZip,
    // global defaults
    primaryContactSameAsTechnical: recentComplaint.pcIsTc
      ? recentComplaint.pcIsTc.toString()
      : '0',
    ...commonFields,
  };
  return complaint;
}
