import { isBefore, isAfter, format, addDays } from 'date-fns';

export function getDefaultDateTime() {
  const currentDateTime = new Date();
  const startTime = new Date(
    currentDateTime.getFullYear(),
    currentDateTime.getMonth(),
    currentDateTime.getDate(),
    6,
    0,
    0,
  );
  const endTime = new Date(
    currentDateTime.getFullYear(),
    currentDateTime.getMonth(),
    currentDateTime.getDate(),
    23,
    59,
    59,
  );
  const isNextDay =
    isAfter(currentDateTime, startTime) && isBefore(currentDateTime, endTime);

  if (isNextDay) {
    const nextDay = addDays(currentDateTime, 1);
    return {
      date: format(nextDay, 'yyyy-MM-dd'),
      time: '11:00',
      ampm: 'pm',
    };
  }

  return {
    date: format(currentDateTime, 'yyyy-MM-dd'),
    time: '11:00',
    ampm: 'pm',
  };
}
