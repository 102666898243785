import { apiRequest } from '@ventera-corporation/vstart-components-react';

async function get(url: string, options = {}) {
  const result = await apiRequest(url, {
    method: 'get',
    // @ts-ignore
    throwErrors: false,
    ...options,
  });
  return result;
}

async function post(url: string, data: any) {
  const result = await apiRequest(url, {
    method: 'post',
    data,
    // @ts-ignore
    throwErrors: false,
  });
  return result;
}

async function put(url: string, data: any) {
  const result = await apiRequest(url, { method: 'put', data });
  return result;
}

export const api = {
  get,
  put,
  post,
};
