import React from 'react';

import { useQuery } from 'react-query';

import { VLoader } from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

function AssociatedEmails() {
  const { data = {} as any, isLoading } = useQuery(
    ['user_recent_complaints'],
    () => API.getAssociatedEmails(),
    { refetchOnWindowFocus: false },
  );

  if (isLoading) return <VLoader />;

  const emails = data?.data || [];

  return (
    <div className="m-0 p-0">
      <h3>Associated Email Addresses</h3>
      <p>
        <small className="mb-1 form-text">
          The following email addresses receive interference complaint report
          notification. If you wish to update this information, please contact
          our <a href="mailto:interferencesitesupport@ventera.com">Help Desk</a>
          .{' '}
        </small>
      </p>
      <ul>
        {emails.map((email: string) => (
          <li key={email}>{email}</li>
        ))}
      </ul>
    </div>
  );
}

export default AssociatedEmails;
