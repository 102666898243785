import logo from 'assets/images/logo.svg';
import { isBefore } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { Outlet, Route, Routes } from 'react-router-dom';

import {
  VAuthenticatedRoute,
  VHeader,
  VPageHeader,
  useUserMenu,
  useAuthentication,
} from '@ventera-corporation/vstart-components-react';

import AcknowledgeComplaint from 'modules/complaints/pages/acknowledge-complaint/AcknowledgeComplaint';
import CreateComplaint from 'modules/complaints/pages/create-complaint/CreateComplaint';
// eslint-disable-next-line import/order
import CreateComplaintSuccess from 'modules/complaints/pages/create-complaint-success/CreateComplaintSuccess';
import ViewComplaint from 'modules/complaints/pages/view-complaint/ViewComplaint';
import { NotFound } from 'modules/error/pages/not-found/NotFound';
import Help from 'modules/help/pages/Help';
import { getExactDate } from 'modules/landing/components/run-reports/utils/get-exact-date';
import CarrierLanding from 'modules/landing/pages/carrier-landing/CarrierLanding';
import FCCLanding from 'modules/landing/pages/fcc-landing/FCCLanding';
import Landing from 'modules/landing/pages/landing/LandingPage';
import SignIn from 'modules/sign-in/pages/sign-in/SignInPage';

import * as PAGES from 'site-map';

import './AppRouter.scss';

function AppRouter() {
  const { menuItems } = useUserMenu({ signInText: 'Log In' });
  const { user } = useAuthentication();
  const { pathname } = useLocation();

  const homeLink = user
    ? { label: 'Home', action: PAGES.PAGE_HOME(), showItem: true }
    : {};
  const [profileLinks, signInLink] = menuItems;
  const signInOutLink = user ? profileLinks?.children?.[1] : signInLink;
  const menu = [
    { ...homeLink },
    {
      label: 'Create Interference Complaint',
      action: PAGES.PAGE_CREATE_COMPLAINT(),
      showItem: !user?.isFcc && !user?.isCarrier,
    },
    {
      label: 'Help',
      action: PAGES.PAGE_HELP(),
      showItem: true,
    },
    { ...signInOutLink },
  ];

  let homePage = <Landing />;
  if (user?.isCarrier) {
    homePage = <CarrierLanding />;
  }
  if (user?.isFcc) {
    homePage = <FCCLanding />;
  }

  return (
    <main className="pb-app-body" data-testid="app-router">
      {isBefore(new Date(), getExactDate('2024-04-07')) ? (
        <div className="info-banner">
          <p className="p-4">
            Welcome to the new version of the Public Safety 800mhz Interference
            website. If you have any questions, please contact our 24x7
            technical support toll-free at 866-729-2204 or send an email to{' '}
            <a href="mailto:InterferenceSiteSupport@ventera.com">
              InterferenceSiteSupport@ventera.com
            </a>
            .
          </p>
        </div>
      ) : null}
      <header className={pathname.replaceAll('/', '')}>
        <VHeader
          navLinks={menu}
          logo={(<img src={logo} alt="800MHz" />) as any}
          position="static"
        />
        <VPageHeader homeLinkText="Home" />
      </header>
      <Routes>
        <Route
          element={
            <VAuthenticatedRoute>
              <Outlet />
            </VAuthenticatedRoute>
          }
        >
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={homePage} />
        </Route>
        <Route path={PAGES.PAGE_SIGN_IN()} element={<SignIn />} />
        <Route
          path={PAGES.PAGE_CREATE_COMPLAINT()}
          element={<CreateComplaint />}
        />
        <Route
          path={PAGES.PAGE_CREATE_COMPLAINT_SUCCESS()}
          element={<CreateComplaintSuccess />}
        />
        <Route path={PAGES.PAGE_VIEW_COMPLAINT()} element={<ViewComplaint />} />
        <Route path={PAGES.PAGE_HELP()} element={<Help />} />
        <Route
          path={PAGES.PAGE_ACKNOWLEDGE_COMPLAINT()}
          element={<AcknowledgeComplaint />}
        />
      </Routes>
    </main>
  );
}

export default AppRouter;
