import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
  VErrorBoundary,
  VSkipNav,
  AuthenticationContextProvider,
  configureApiRequest,
} from '@ventera-corporation/vstart-components-react';

import AppRouter from './app-router/AppRouter';
import Footer from './footer/Footer';
import './App.scss';
import { getStore } from './redux/store';

const queryClient = new QueryClient();
const store = getStore();

configureApiRequest(store.dispatch);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <VErrorBoundary>
          <AuthenticationContextProvider>
            <VSkipNav>
              <QueryClientProvider client={queryClient}>
                <AppRouter />
                <Footer />
              </QueryClientProvider>
            </VSkipNav>
          </AuthenticationContextProvider>
        </VErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
