import * as yup from 'yup';

export const schema = yup.object().shape({
  startDate: yup
    .date()
    .required('Start Date is a required field')
    .max(yup.ref('endDate'), 'Start Date cannot be after End Date')
    .typeError('End Date is invalid'),
  endDate: yup
    .date()
    .required('Start Date is a required field')
    .min(yup.ref('startDate'), "End Date can't be before Start Date")
    .max(new Date(), 'End Date cannot be in the future.')
    .typeError('End Date is invalid'),
});
