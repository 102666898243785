import React from 'react';

import {
  VSingleColView,
  VTextAreaField,
  VSelectField,
} from '@ventera-corporation/vstart-components-react';

import { RefDataObject } from 'modules/types';

import DateTime from '../date-time/DateTime';

interface MeetingDetailsProps {
  timezones: RefDataObject[];
}
function MeetingDetails({ timezones = [] }: MeetingDetailsProps) {
  return (
    <div className="meeting-details-wrapper">
      <VSingleColView size="full">
        <p className="font-weight-bold mb-4">
          Please choose a day, time, and location for a meeting between your
          organization and any carriers who may be operating in the area where
          you are experiencing interference. The purpose of this meeting is to
          determine the source of the interference. Ideally, a representative
          from your organization who will be able to reproduce the interference
          should attend.
        </p>

        <p>
          A suggested date and time are pre-filled below. You may adjust the
          time and date for the meeting but only forward from the suggestion.
          Please be as specific as possible when describing the meeting
          location.
        </p>
      </VSingleColView>

      <DateTime
        name="meeting"
        dateLabel="Date of Meeting"
        timeLabel="Time of Meeting"
      />

      <div className="col pb-2 mt-2">
        <div className="select-field">
          <VSelectField
            // @ts-ignore
            name="meetingTimezone"
            label="Time Zone"
            options={timezones.map((t) => ({
              value: t.value.toString(),
              label: t.label,
            }))}
            required
          />
        </div>
      </div>

      <div className="col pb-2">
        <VTextAreaField
          // @ts-ignore
          name="meetingLocation"
          label="Location"
          maxLength="4000"
          required
        />
      </div>

      <div className="col pb-2">
        <VTextAreaField
          // @ts-ignore
          name="meetingAddlNotes"
          label="Additional Notes"
          maxLength="4000"
        />
      </div>
    </div>
  );
}

export default MeetingDetails;
