import React from 'react';

import {
  VSingleColView,
  VInputField,
} from '@ventera-corporation/vstart-components-react';

function SubmitterEmail() {
  return (
    <div className="call-sign-wrapper">
      <VSingleColView size="full">
        <h4 className="font-weight-bold mb-4">Submitter Email</h4>
      </VSingleColView>
      <div className="col pb-2">
        <p>
          Although account registration is not necessary, a valid email address
          is required to submit an interference report.
        </p>
        <VInputField
          // @ts-ignore
          name="submittedBy"
          label="Email Address"
          required
        />
      </div>
    </div>
  );
}

export default SubmitterEmail;
