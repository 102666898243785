import React, { useEffect } from 'react';

import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';

import {
  VButton,
  VButtonRow,
  cognitoUrl,
  usePageUpdate,
} from '@ventera-corporation/vstart-components-react';

import * as PAGES from 'site-map';

function CreateComplaintSuccess() {
  const navigate = useNavigate();
  const { state = {} as any } = useLocation();
  const { email = '', complaintData } = state || {};

  usePageUpdate({
    documentTitle: PAGES.PAGE_CREATE_COMPLAINT.title,
    header: {
      breadCrumbs: [
        {
          title: 'Create Interference Complaint',
          link: PAGES.PAGE_CREATE_COMPLAINT(),
        },
        { title: 'Submission Confirmation' },
      ],
    },
  });

  useEffect(() => {
    if (!email) {
      navigate(PAGES.PAGE_HOME());
    }
  }, [email, navigate]);

  return (
    <div className="create-complaint-success-wrapper ctia-content-wrapper">
      <div className="ctia-content-title">
        <Container className="my-4 d-flex">
          <h1>Success!</h1>
        </Container>
      </div>
      <div className="ctia-content">
        <Container>
          <p>
            <strong>Thank you!</strong> Your interference report has been
            submitted. A record has been sent to {email || 'your email'}.
          </p>

          <p>
            <VButton
              variant="link"
              onClick={() =>
                navigate(`/complaint/${complaintData?.id}`, {
                  state: { complaintData },
                })
              }
            >
              View submitted complaint
            </VButton>
          </p>

          <p>
            If you create an account, you can view your submitted reports and be
            able to fill out a new interference report more quickly.
          </p>

          <VButtonRow className="mt-4">
            <VButton href={cognitoUrl(PAGES.PAGE_HOME(), true)}>
              Create Account
            </VButton>
          </VButtonRow>

          <VButtonRow>
            <VButton className="me-2" onClick={() => navigate('/')}>
              Return to Homepage
            </VButton>
            <VButton
              variant="outline-primary"
              onClick={() => navigate(PAGES.PAGE_CREATE_COMPLAINT())}
            >
              Create Another Complaint
            </VButton>
          </VButtonRow>
        </Container>
      </div>
    </div>
  );
}

export default CreateComplaintSuccess;
